$color-blue: #2B6DBA;
$color-blue-blur: #519DD9;
$color-dark: #111111;
$color-dark-blur-1: #707070;
$color-dark-blur-2: #CFCFCF;
$color-white: #FFFFFF;
$royal-blue: #0086d3;
$color-active-table: #d8eefb;
$text-color-dashboard: #03f9cc;

$color-white: #fff;
$color-gunmetal-gray: #343A40; //backgound header menu
$color-light-cyan-active: #E6FFFB;//hover, active cho table menu
$color-light-aqua-active: #98E9DC;//active cho menu sơ đồ
$color-bright-cyan: #4176a4; //text, backgound button
$color-steel-blue: #17A2B8; // border, text cho bộ lock table
$color-orange: #FFC107;
$color-crimson-red: #DC3545;
$color-backgound: #F0F2F5;
$color-text-disabled: #F0F2F5;
$color-gray: #707070;
$color-border-table: #DEE2E6;
$color-silver: #CED4DA;
$color-text-table: #212529;
$color-green:#28A745;
$color-dark-orange:#FF9C07;
$color-dark-red:#DC3545;
$color-navy-blue: #002B5B;
$color-teal-active-hover: #B3E8E5;

//tree select
$color-gunmetal : #6C757D ;

// HomePage
$color-main-bg-cyan: #62B1EE;
$color-main-bg-deep: #052958;
$color-title-software-yellow: #FEB74D;
$color-title-software-orange: #F37D01;
$color-text-shadow: rgba(0, 0, 0, 0.25);
$color-bg-circle-cyan: #0094FF;
$color-bg-circle-lime: rgba(0, 255, 224, 0.47);
$color-bg-circle-blur: rgba(217, 217, 217, 0.00);
$color-bg-btn-deep: #2A5298;
$color-bg-btn-dark: #1E3C72;
$color-bg-btn-link-hover: rgba(68, 214, 233, 0.50);
$color-cirle-animation-1: #44D6E9;
$color-cirle-animation-2: #095D6A;
$color-main-title-card: #e6f7ff;

$default-font-size: 14px;
$color-text-cyan: #1a5e83;
$color-gray-1: #f1f3f5;
$color-border-gray: #dee2e6;
$color-border-blue: #0099ff;
