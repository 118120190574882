@import '../../modules/styles/variable';
.header-dashboard {
  font-weight: bold;
  color: $text-color-dashboard;
  font-size: 30px;
  padding-top: 21px;
  padding-bottom: 22px;
}

.dash-board-container {
    background-color:#656464;
}

.date {
  color: #fcff81;
  font-weight: bold;
  font-size: larger;
}
.time {
  color: #03f939;
  font-weight: bold;
  font-size: x-large;
}
.number-map {
  line-height: 55px;
  color: whitesmoke;
  font-weight: bold;
}

.bg-color-tab{
  background-color: #3E3939 !important;
}

.tt-du-bao {
    height:calc(100vh - 190px);
    overflow: scroll;

}

.tt-du-bao {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.tt-du-bao::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.ds-truong-hop-benh {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  height: calc(100vh - 278px);
  overflow: scroll;
}

.postion-parent-tabs {
  position: relative;
  .custom-position-tabs {
    border-bottom: none;
    position: absolute;
    z-index: 9999;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black !important;
    li > button {
        transition: 0.1s ease-in-out;
        background-color: transparent;
        color: #fff !important;
        &:not(.active) {
            color: black;
            &:hover{
            border-bottom: 2px solid #ffc107;
            }
        };
        &.nav-link.active {
            color: #ffc107 !important;
            font-weight: normal;
            background-color: #343a40;
        }
    }

  }
}

.tab-bieu-do-container {
  position: relative;

  #icon-expand-chart {
    z-index: 999;
    right: 40px;
    top: 40px;
    transition: 0.5s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.5);
      color: #03f939;
    }
  }

  .tab-content {
    height: calc(100vh - 278px);

  }
}

.tab-bieu-do {
  background-color: black !important;

  li>button {
    background-color: black !important;
    transition: 0.1s ease-in-out;
    background-color: transparent;
    color: #fff !important;

    &:not(.active) {
      color: black;

      &:hover {
        border-bottom: 2px solid #ffc107;
      }
    }

    ;

    &.nav-link.active {
      color: #ffc107 !important;
      font-weight: normal;
      background-color: #343a40;
    }
  }
}

#icon-expand {
  transition: 0.5s ease-in-out;
  &:hover {
    cursor: pointer;
    transform:  scale(1.5);
    color: #03f939;
  }
  top: 30px;
  right: 10px !important;
  z-index: 2;
}

.tab-content:fullscreen {
  width: 100%;
  height: 100%;
  background-color: white; /* Thay đổi nếu cần */
  .leaflet-container {
    height: 100vh !important;
  }
}

// tạo border animation
@keyframes border-animation {
  0% {
    border-color: red;
  }
  25% {
    border-color: blue;
  }
  50% {
    border-color: green;
  }
  75% {
    border-color: yellow;
  }
  100% {
    border-color: red;
  }
}

#kt_app_content_container,
.app-content:has(.dash-board-container) {
  padding: 0 !important;
}

#kt_app_header {
  background-color: #100c2a;
  position: sticky;
  top: 0;
  z-index: 9999;
}

#kt_app_wrapper {
  margin-top: 0 !important;
}

.border-animate {
  border: 4px solid red;
  animation: border-animation 2s linear infinite;
}

@media screen and (max-width: 1200px) {
  .tt-du-bao {
    height: calc(150vh);
  }
}

@media screen and (max-width: 768px) {
  #box-location {
    margin: 0 !important;
  }

  .tt-du-bao {
    height: calc(152vh);
  }

  .ds-truong-hop-benh {
    height: calc(100vh - 230px);
  }
}

@media screen and (max-width: 576px) {
  .tt-du-bao {
    height: 50vh;
    width: 100vw;
    overflow-y: auto;
    -ms-overflow-style: auto;
    scrollbar-width: thin;

    & ::-webkit-scrollbar {
      display: block;
    }
  }

  .tt-tong-quan {
    margin-top: 10px;
    gap: 0px !important;

    .col {
      flex: 1 0 50%;
      padding: 0 !important;
    }
  }

  .postion-parent-tabs {
    margin-right: -20px;
    margin-bottom: 20px;
  }

  .map-container {
    justify-content: center;
  }

  .detail-address-box {
    padding-right: 0px;
  }
}

.app-page:has(.dash-board-container) {
  #kt_app_header {
    display: none;
  }
}

.bg-dark {
  background-color: #0E0D0D !important;
}