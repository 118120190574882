.dropdown {
  // &:hover .dropdown-menu{
  //   display: block;
  //   margin-top: 0; // Remove any margin that might be added by Bootstrap
  // }
  .dropdown-menu {
    position: fixed !important;
  }
}

.app-header-menu {
  display: flex;
  flex-wrap: nowrap; /* Đảm bảo rằng các phần tử con không bị cuộn */
  overflow-x: hidden;
}

.mobie_menu {
 .item {
  position: relative;
  transition: height 0.5s ease-in-out;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  &::before {
    content: '+';
    position: absolute;
    top: 0;
    right: 10px !important;
  }
  &:hover {
    height: 130px;
    &::before {
      content: '-';
      position: absolute;
      top: 0;
      right: 10px !important;
    }
  }
  &:last-child{
    border-bottom: none !important;
  }
 }
}

@media only screen and (max-width: 991.98px) {
  .header-user-info.color-white, .svg-icon.svg-icon-1 {
    color: #181C32 !important;
  }
}

@media only screen and (min-width: 991.98px) {
  .mobie_menu {
    display: none !important;
  }
}
