@import '../../../styles/variable';


.page-container {
    display: flex;
    gap: 10px;
    margin-top: 5px;

    .title {
        font-size: 13px;
        text-transform: uppercase;
        color: $color-text-cyan;
        font-weight: 600;
        line-height: 15.23px;
    }

    .ds-header {
        padding: 10px 12px 3px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left-content-container {
        width: 40%;
        background-color: white;
        padding-bottom: 10px;

        .ds-search-box {
            display: flex;
            padding: 10px 12px;
            align-items: center;
            gap: 10px;

            .box-search {
                width: 400px;
            }
        }

        .status-box {
            padding: 16px 24px;
            display: flex;
            gap: 10px;
            flex-direction: column;

            .status-box-item {
                display: flex;
                align-items: center;
                min-width: 140px;
                gap: 10px;
            }
        }
    }

    .right-content-container {
        position: relative;

        .tt-tabs {
            .customs-tabs {
                height: 40px;

                .nav-link {
                    height: 32px;
                }
            }

            .tab-content .tab-pane {
                height: calc(100vh - 145px);
                background: $color-white !important;
            }
        }

        .orther-info-tab-container {
            .main-content-title {
                font-size: 14px;
                font-weight: bold;
                padding: 8px 12px;
                color: #1a5e83;
            }
        }
    }

}

.ds-thb {
    .table-responsive {
        height: calc(100vh - 400px) !important;
    }
}

.noiDungTruongHopBenh {
    .tab-content {
        height: calc(100vh - 345px) !important;
        overflow: auto;
    }
}

.smooth-transition {
    transition: opacity 0.5s ease, transform 0.5s ease
}

@media screen and (max-width: 900px) {
    .page-container{
        margin-bottom: 100px !important;
        display: flex;
        flex-direction: column;
        .left-content-container{
            width: 100% !important;
        }
        .left-content-container {
            width: 100% !important;
        }
    }
    .right-content-container {
            width: 100% !important;
            .tt-tabs ul{
                left: unset !important;
            }
            .customs-tabs {
                overflow: hidden;
                position: fixed !important;
                right: 10%!important;
                top: 10%;
                z-index: 2;
                display: flex;
                flex-direction: column;

                text-align: center;
                padding: 0 0 !important;
                height: 50px !important;
                width: 50px !important;
                border-radius: 30px;
                transition: 0.5s ease-in-out;
                &::before {
                    content: "+";
                    cursor: grab;
                    color: white;
                    font-size: xx-large;
                    position: absolute;
                    z-index: 3;
                    width: 100%;
                    height: 100%;
                    background-color: #B3E5FC;
                }
                &:hover {
                    height: 235px !important;
                    width: 163px !important;
                    border-radius: 0%;
                    &::before {
                        display: none;
                    }
                }
                .nav-link.active{
                    width: 100%;
                    &::before {
                        display: none;
                    }
                }
            }
        }
}

.btn-recycle {
    &:hover {
        cursor: pointer;
    }
}
