$color: #2980b9;
$scale: 30px;
$radius: 50%;
$space: 4px;

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 $space $color,
                0 0 0 ($space + 3px) darken($color, 30%),
                0 0 0 ($space + 6px) $color,
                0 0 0 ($space + 9px) darken($color, 30%),
                0 0 0 ($space + 12px) $color;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 ($space * 2) $color,
                0 0 0 ($space * 3 + 3px) darken($color, 30%),
                0 0 0 ($space * 4 + 6px) $color,
                0 0 0 ($space * 5 + 9px) darken($color, 30%),
                0 0 0 ($space * 6 + 12px) $color;
    opacity: 0;
  }
}

.data-point-default {
  position: absolute;
  border-radius: 50%;
  background-color: rgb(15, 145, 209);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    animation: none;
  }

  &:hover::after {
    animation: pulse 1.5s infinite;
  }
}

.leaflet-div-icon {
  width: 0px !important;
  height: 0 !important;
}

.leaflet-container,
.leaflet-interactive {
  cursor: crosshair !important;
}