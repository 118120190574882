.search-container {

    .section-container {
        padding: 12px 20px 15px;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #e7ecf1;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    }
}

@media only screen and (max-width: 991.98px) {
    .search-container{

        .search-action{
            margin-top: 4px;
            justify-content: end;
        }
    }
}