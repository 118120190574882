.section-container {
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e7ecf1;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    height: 100%;
}

.button-group-odich {
    padding: 15px 20px;
    background-color: white;
    border: 1px solid #e0e4e8;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    position: sticky;
    bottom: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
    z-index: 1;
}

@media only screen and (max-width: 991.98px) {
    .search-container{
        .search-action{
            margin-top: 4px;
            justify-content: end;
        }
    }
}

.them-moi-o-dich-container {
    background-color: white;
    padding: 0 12px;
}